const data = [
  {
    src:
      "https://archdesignsstudio.com/assets/projects/kandytransitterminal/pic1.jpg",
    text: "1",
  },
  {
    src:
      "https://archdesignsstudio.com/assets/projects/kandytransitterminal/pic2.jpg",
    text: "2",
  },
  {
    src:
      "https://archdesignsstudio.com/assets/projects/kandytransitterminal/pic3.jpg",
    text: "3",
  },
  {
    src:
      "https://archdesignsstudio.com/assets/projects/kandytransitterminal/pic4.jpg",
    text: "4",
  },
  {
    src:
      "https://archdesignsstudio.com/assets/projects/kandytransitterminal/pic5.jpg",
    text: "5",
  },
];

export default data;
